/* index.css */
@font-face {
  font-family: 'Raleway';
  src: url('./assets/fonts/Raleway/700\ Bold/Raleway-Bold.ttf')
    format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('./assets/fonts/OpenSans/400\ Regular/OpenSans-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Light mode body text: dark gray */
  body {
    @apply text-base font-body text-black dark:text-white sm:break-words;
  }
  
  /* Dark mode headings: slightly lighter than pure white for a softer look */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading font-bold text-primary;
  }
  
  /* Specific heading sizes */
  h1 {
    @apply text-5xl;
  }
  h2 {
    @apply text-4xl;
  }
  h3 {
    @apply text-3xl;
  }
  h4 {
    @apply text-2xl;
  }
  h5 {
    @apply text-xl;
  }
  h6 {
    @apply text-lg;
  }
}

/* Additional utilities */
@layer utilities {
  /* Animation delay utilities */
  .delay-2000 {
    animation-delay: 2s;
  }
  .delay-3000 {
    animation-delay: 3s;
  }
  .delay-4000 {
    animation-delay: 4s;
  }
  /* Lighter shade of primary for headings in dark mode */
  /* .text-primary-light {
    color: #8fd3e9;
  } */
}

/* Blog specific styles - using @layer components to organize within Tailwind */
@layer components {
  /* Clean up Elementor containers in blog posts */
  .blog-content .elementor-element,
  .blog-content .e-con-inner,
  .blog-content .elementor-widget-container {
    all: unset;
    display: block;
    width: 100%;
  }
  
  /* Improved layout for Elementor sections and columns */
  .blog-content .elementor-section,
  .blog-content .e-con {
    @apply flex flex-wrap my-8;
  }
  
  .blog-content .elementor-column,
  .blog-content .elementor-inner-column,
  .blog-content .e-child {
    @apply flex-1 min-w-[300px] px-4;
  }
  
  /* Ensure proper list styling in blog content */
  .blog-content ul:not([class]) {
    @apply list-disc pl-5 mb-4;
  }
  
  .blog-content ol:not([class]) {
    @apply list-decimal pl-5 mb-4;
  }
  
  .blog-content li:not([class]) {
    @apply mb-2;
  }
  
  /* Fix legacy Elementor components */
  .blog-content .elementor-widget-image {
    @apply text-center my-8;
  }
  
  .blog-content .elementor-button {
    @apply inline-block bg-primary text-white font-medium py-3 px-6 rounded-md my-4 transition-colors hover:bg-button-dark no-underline;
  }
  
  .blog-content .elementor-divider {
    @apply border-t border-gray-200 dark:border-gray-700 my-8;
  }
  
  /* Blog post image styling - NEW */
  .blog-content img {
    @apply max-w-full h-auto rounded-lg mx-auto my-6 block;
  }

  /* Different sizing for desktop vs mobile */
  @media (min-width: 769px) {
    .blog-content img {
      @apply max-w-[85%];
    }
  }

  /* Small icon images */
  .blog-content img.icon-image {
    @apply max-w-[120px] my-4 mx-auto;
  }

  /* Image containers */
  .blog-content .image-container,
  .blog-content .section-image {
    @apply text-center my-8;
  }

  /* Grid for screenshots */
  .blog-content .screenshots-grid {
    @apply grid grid-cols-1 md:grid-cols-2 gap-6 my-8;
  }

  .blog-content .screenshots-grid img {
    @apply my-0; /* Remove margin from grid items */
  }

  /* Call to action button styling */
  .blog-content .cta-button {
    @apply text-center my-8;
  }

  /* Improved styles for Elementor images specifically */
  .blog-content .elementor-widget-image img {
    @apply inline-block max-w-full md:max-w-[85%] h-auto rounded-lg;
  }
  
  /* Responsive fixes */
  @media (max-width: 768px) {
    .blog-content .elementor-column,
    .blog-content .elementor-inner-column,
    .blog-content .e-child {
      @apply w-full p-0;
    }
  }
  
  /* Code blocks styling */
  .blog-content pre {
    @apply bg-gray-100 dark:bg-gray-800 p-4 rounded-md overflow-x-auto mb-6 text-sm;
  }
  
  /* Links styling */
  .blog-content a:not([class]) {
    @apply text-primary hover:text-button-dark underline;
  }
}

/* Blog content typography styling for dark mode */
.blog-content {
  --tw-prose-body: #374151;
  --tw-prose-headings: var(--color-primary);
  --tw-prose-lead: #4b5563;
  --tw-prose-links: var(--color-primary);
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
}

.dark .blog-content {
  --tw-prose-body: #d1d5db;
  --tw-prose-headings: var(--color-primary);
  --tw-prose-lead: #9ca3af;
  --tw-prose-links: var(--color-primary);
  --tw-prose-bold: #f3f4f6;
  --tw-prose-counters: #9ca3af;
  --tw-prose-bullets: #4b5563;
  --tw-prose-hr: #374151;
  --tw-prose-quotes: #f3f4f6;
  --tw-prose-quote-borders: #374151;
  --tw-prose-captions: #9ca3af;
  --tw-prose-code: #f3f4f6;
  --tw-prose-pre-code: #d1d5db;
  --tw-prose-pre-bg: #111827;
  --tw-prose-th-borders: #4b5563;
  --tw-prose-td-borders: #374151;
}